.flexslider {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.result--images__jstl {
    padding: 0px;
}

.flexslider .flex-direction-nav a,
.flexslider .flex-direction-nav a {
    display: block; /* I added this line */
}

.flexslider ul.flex-direction-nav li.flex-nav-next a {
    margin-right: 0;
}

.flexslider ul.flex-direction-nav li.flex-nav-prev a {
    margin-left: 0;
}

.flex-direction-nav .flex-next {
    right: 0 !important;
    opacity: 1 !important;
}

.flex-direction-nav .flex-prev {
    left: 0 !important;
    opacity: 1 !important;
}

.flex-direction-nav a {
    height: 100% !important;
    top: 5%;
    width: 20% !important;
}

.flex-direction-nav a.flex-next:before {
    font-family: FontAwesome;
    content: '\f105';
    position: relative;
    top: 39%;
    margin-right: 22px;
}

.flex-direction-nav a:before {
    font-family: FontAwesome;
    content: '\f104';
    color: #ffffff;
    font-size: 50px;
    position: relative;
    top: 39%;
    margin-left: 22px;
}
